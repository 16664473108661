.HomeHeader1064646b83684e54Aa636990243a3928 {
  background-color: #24282b
  position: relative;
  color: white;
}

.HomeHeader1064646b83684e54Aa636990243a3928 .header-top-wrapper {
  position: relative;
  background-color: #79253d;
}

.HomeHeader1064646b83684e54Aa636990243a3928 .header-top-opacity {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1.0;
  background-color: rgb(121, 37, 61);
}

.HomeHeader1064646b83684e54Aa636990243a3928 .hero-wrapper {
  background-color: #050606;
}

.HomeHeader1064646b83684e54Aa636990243a3928 .hero-wrapper .background-image {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: url(https://mp1md-pub.s3.amazonaws.com/orgs/tastesoftantra/hero4.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 100%;
  opacity: 0.7;
}

.HomeHeader1064646b83684e54Aa636990243a3928 .hero-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 35rem;
  padding: 3.25rem 1rem;
}

.HomeHeader1064646b83684e54Aa636990243a3928 .hero-content .hero-header {
  font-family: Great Vibes;
  font-size: 300%;
  font-weight: 500;
  line-height: 4rem;
  margin-bottom: 1.2rem;
  letter-spacing: .15rem;
  text-align: center;
}

@media (min-width: 900px) {
    .HomeHeader1064646b83684e54Aa636990243a3928 .hero-content .hero-header {
        margin-top: 2.2rem;
        text-align: left;
    }
}

.HomeHeader1064646b83684e54Aa636990243a3928 .hero-content .hero-header .external-content p {
  margin: 0;
}

.HomeHeader1064646b83684e54Aa636990243a3928 .hero-content .buttons {
  display: flex;
  justify-content: center;
  margin-top: 17rem;
}

@media (min-width: 900px) {
  .HomeHeader1064646b83684e54Aa636990243a3928 .hero-content .buttons {
    display: block;
    justify-content: start;
    margin-top: 1rem;
  }
}

.HomeHeader1064646b83684e54Aa636990243a3928 .hero-content .buttons .Button {
  background-color: var(--accent-color-lighter);
  border: 1px solid white;
}

@media (min-width: 640px) {
  .HomeHeader1064646b83684e54Aa636990243a3928 .hero-content {
    justify-content: flex-start;
    padding: 2rem 3rem;
  }
  .HomeHeader1064646b83684e54Aa636990243a3928 .hero-wrapper .background-image {
      background-size: auto 137%;
  }
}

@media (min-width: 900px) {
  .HomeHeader1064646b83684e54Aa636990243a3928 .header-top-wrapper {
  }
}
.MarketingBodyF55a1e6a1d5e40a2B4d0493e62ff01cb {
  padding: 2em;
  background-color: #7e82bf;
  color: white;
}

.MarketingBodyF55a1e6a1d5e40a2B4d0493e62ff01cb .external-content a {
}

.MarketingBodyF55a1e6a1d5e40a2B4d0493e62ff01cb .MarketingCards {
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  gap: 7rem;
}

.MarketingBodyF55a1e6a1d5e40a2B4d0493e62ff01cb .MarketingCards .MarketingCard {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3rem;
}

@media (min-width: 768px) {
  .MarketingBodyF55a1e6a1d5e40a2B4d0493e62ff01cb .MarketingCards .MarketingCard {
    gap: 6rem;
  }
}

.MarketingBodyF55a1e6a1d5e40a2B4d0493e62ff01cb .MarketingCards .MarketingCard .ImageWrapper img {
  max-width: 400px;
}

@media (min-width: 768px) {
  .MarketingBodyF55a1e6a1d5e40a2B4d0493e62ff01cb .MarketingCards .CardTextContent {
    width: 25rem;
  }
}

.MarketingBodyF55a1e6a1d5e40a2B4d0493e62ff01cb .MarketingCards .CardHeading {
  color: white;
  font-family: Playfair Display;
  font-size: 3rem;
  letter-spacing: 0.1rem;
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
  line-height: 1;
}
@media (min-width: 768px) {
  .MarketingBodyF55a1e6a1d5e40a2B4d0493e62ff01cb .MarketingCards .CardHeading {
    padding-left: 0;
    padding-right: 0;
    line-height: 1.5;
  }
}

.MarketingBodyF55a1e6a1d5e40a2B4d0493e62ff01cb .MarketingCards .CardText {
  font-family: Playfair Display;
  font-size: 1.25rem;
  letter-spacing: 0.05rem;
}

.MarketingBodyF55a1e6a1d5e40a2B4d0493e62ff01cb .MarketingCards .ButtonWrapper {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.MarketingBodyF55a1e6a1d5e40a2B4d0493e62ff01cb .MarketingCards .Button {
  color: white;
  font-family: 'Playfair Display'; 
  font-weight: 700; 
  font-size: 1.5rem;
  letter-spacing: 0.1rem;
  text-decoration: none;
  border: 1px solid white;
  padding: 0.75rem 3rem;
}

.MarketingBodyF55a1e6a1d5e40a2B4d0493e62ff01cb .MarketingCards .Button:hover {
  color: white;
  text-decoration: none;
}
.MessagePagesBbc514a6F5e0487897f6Ae00b27e0983 {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}
.SecondaryNav575d8e4fD8bc4766A7ac9496eb9a6476 {
  padding: 1rem 0;
}
.AboutLevelingPage31b1453585cb412eA10018d0c90dfc01 .Button {
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  background: rgb(120, 0, 80);
  letter-spacing: 0.1rem;
}
.SecondaryMarketingHero9950f1d7Ee48493992b473ae7cc03b0e {
  align-items: center;
  color: #030C22;
  display: flex;
  flex-grow: 1;
  padding-left: 5rem;
}

.SecondaryMarketingHero9950f1d7Ee48493992b473ae7cc03b0e .title {
  color: #500064;
  font-family: 'Playfair Display';
  font-size: 300%;
  font-weight: 500;
  line-height: 100%;
}

@media (min-width: 640px) {
  .SecondaryMarketingHero9950f1d7Ee48493992b473ae7cc03b0e .title {
    font-size: 400%;
  }
}

.SecondaryMarketingHero9950f1d7Ee48493992b473ae7cc03b0e .button > a {
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  color: white;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 110%;
  text-transform: uppercase;
  display: inline-block;
  padding: 0.5rem 2rem;
  background-color: #ed2e38;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.SecondaryMarketingHero9950f1d7Ee48493992b473ae7cc03b0e .button > a:hover {
  --tw-brightness: brightness(1.1);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.AboutUsPage8272e800F2a64481Bdf8Aff6220b4314 .main {
  background-color: #888;
  color: white;
  font-family: Playfair Display;
  font-size: 200%;
  padding: 2rem;
}
.HomePage40cad30dD7f9420dAb0b10fff5ac9ade .PageQuote {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.HomePage40cad30dD7f9420dAb0b10fff5ac9ade .PageQuote > * {
  flex-grow: 1;
}

.HomePage40cad30dD7f9420dAb0b10fff5ac9ade .PageQuote .Image {
  object-fit: cover;
}

.HomePage40cad30dD7f9420dAb0b10fff5ac9ade .PageQuote .TextContent {
  background-color: black;
  color: white;
  padding: 3rem;
}

.HomePage40cad30dD7f9420dAb0b10fff5ac9ade .PageQuote .Title {
  font-family: "Playfair Display";
  font-size: 250%;
  line-height: 100%;
}

.HomePage40cad30dD7f9420dAb0b10fff5ac9ade .PageQuote .Text {
  font-family: 'Playfair Display';
  font-weight: 300;
  font-size: 115%;
  letter-spacing: 0.05rem;
  line-height: 1.8rem;
}

@media (min-width: 900px) {
  .HomePage40cad30dD7f9420dAb0b10fff5ac9ade .PageQuote > * {
    width: 50%;
    height: 26rem;
  }
}

@media (min-width: 1200px) {
  .HomePage40cad30dD7f9420dAb0b10fff5ac9ade .PageQuote .Title {
    font-size: 300%;
    line-height: 125%;
  }
}
.HomePageHeroFe76cbcdC5cc41e2A49d4e2320e0c5aa {
  color: white;
  min-height: 35rem;
  padding: 1.5rem;
  position: relative;
  text-align: left;
}

@media (min-width: 768px) {
  .HomePageHeroFe76cbcdC5cc41e2A49d4e2320e0c5aa {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

.HomePageHeroFe76cbcdC5cc41e2A49d4e2320e0c5aa .title {
  font-family: 'Great Vibes';
  font-size: 300%;
  font-weight: 500;
  line-height: 4rem;
  margin-bottom: 1.2rem;
  letter-spacing: 0.15rem;
  text-align: center;
}

.HomePageHeroFe76cbcdC5cc41e2A49d4e2320e0c5aa .button {
  display: flex;
  justify-content: center;
}

.HomePageHeroFe76cbcdC5cc41e2A49d4e2320e0c5aa .button > a {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  background-color: #5e67f2;
  color: white;
  font-family: 'Playfair Title';
  font-weight: 400;
  font-size: 150%;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  display: inline-block;
  padding: 0.75rem 3rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.HomePageHeroFe76cbcdC5cc41e2A49d4e2320e0c5aa .button > a:hover {
  --tw-brightness: brightness(1.1);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

@media (min-width: 900px) {
  .HomePageHeroFe76cbcdC5cc41e2A49d4e2320e0c5aa .title {
    margin-top: 2.2rem;
    text-align: left;
  }

  .HomePageHeroFe76cbcdC5cc41e2A49d4e2320e0c5aa .button {
    display: block;
    justify-content: start;
  }
}
.DefaultFooterAbebe797019840cf8bb873e98cab7620 {
  background-color: #5e67f2;
  color: white;
  min-height: 10em;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  padding: 2rem;
  font-family: Playfair Display;
}

.gf-customer .DefaultFooterAbebe797019840cf8bb873e98cab7620 a {
  color: white;
}

.DefaultFooterAbebe797019840cf8bb873e98cab7620 > * {
  text-align: center;
}

.DefaultFooterAbebe797019840cf8bb873e98cab7620 .Navigation {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 3rem;
  justify-content: center;
  margin-bottom: 2rem;
}

.DefaultFooterAbebe797019840cf8bb873e98cab7620 .Navigation .Link:hover {
  text-decoration-line: underline;
  --tw-brightness: brightness(1.1);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.DefaultFooterAbebe797019840cf8bb873e98cab7620 > .logo-wrapper {
  width: 15rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.DefaultFooterAbebe797019840cf8bb873e98cab7620 .Logo {
  height: 40px;
  width: 150px;
  fill: currentColor;
}

.DefaultFooterAbebe797019840cf8bb873e98cab7620 .GroupCopyright {
  font-size: 90%;
}

.DefaultFooterAbebe797019840cf8bb873e98cab7620 .GroupFlowTag {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.DefaultFooterAbebe797019840cf8bb873e98cab7620 .GroupFlowTag .LogoWrapper {
  font-size: 90%;
}

.DefaultFooterAbebe797019840cf8bb873e98cab7620 .GroupFlowTag:hover {
    --tw-text-opacity: 1;
    color: rgb(0 194 152 / var(--tw-text-opacity));
}

.DefaultFooterAbebe797019840cf8bb873e98cab7620 .GroupFlowTag .PoweredBy {
  font-size: 90%;
}
.SecondaryPageB90a7b863a3b4ee0B0df45090e04e561 .text-content {
  padding: 0 1rem;
}
.AboutSusan00fefa8d45104a77A9b7C00887756745 {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
/* Offerings */
.AboutOfferingsDbd505f4Ee9b4e5aA9f84f7a8bdd27b2 {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: Playfair Display;
}

.AboutOfferingsDbd505f4Ee9b4e5aA9f84f7a8bdd27b2 main {
  font-size: 1.25rem;
}

.AboutOfferingsDbd505f4Ee9b4e5aA9f84f7a8bdd27b2 .content-width {
  max-width: 1024px;
  margin: 0 auto;
  padding: 2rem;
}

.AboutOfferingsDbd505f4Ee9b4e5aA9f84f7a8bdd27b2 .gf-cols-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 768px) {
  .AboutOfferingsDbd505f4Ee9b4e5aA9f84f7a8bdd27b2 .gf-cols-2 {
    display: grid;
    grid-template-columns: repeat(2,minmax(0,1fr));
  }
}

.AboutOfferingsDbd505f4Ee9b4e5aA9f84f7a8bdd27b2 .text-block-vertical {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.AboutOfferingsDbd505f4Ee9b4e5aA9f84f7a8bdd27b2 .heading {
  font-size: 2.5rem;
  font-family: Great Vibes;
  margin-bottom: 0.5rem;
}

.AboutOfferingsDbd505f4Ee9b4e5aA9f84f7a8bdd27b2 .block-image {
  max-width: 300px;
}

.AboutOfferingsDbd505f4Ee9b4e5aA9f84f7a8bdd27b2 .hero.brave {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: url(https://mp1md-pub.s3.amazonaws.com/orgs/tastesoftantra/images/rocks.webp);
  height: 30rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.AboutOfferingsDbd505f4Ee9b4e5aA9f84f7a8bdd27b2 .hero.brave .text-block {
  text-align: center;
}

.AboutOfferingsDbd505f4Ee9b4e5aA9f84f7a8bdd27b2 .grid-blocks {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.AboutOfferingsDbd505f4Ee9b4e5aA9f84f7a8bdd27b2 .grid-blocks .image {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  height: 380px;
}

.AboutOfferingsDbd505f4Ee9b4e5aA9f84f7a8bdd27b2 .grid-blocks .image img {
  object-fit: contain;
  max-height: 380px;
}

@media (min-width: 640px) {
  .AboutOfferingsDbd505f4Ee9b4e5aA9f84f7a8bdd27b2 .grid-blocks {
    display: grid;
    grid-template-columns: repeat(2,minmax(0,1fr));
    grid-gap: 4rem;
  }
}

.AboutOfferingsDbd505f4Ee9b4e5aA9f84f7a8bdd27b2 .grid-blocks .image-text-block {
}
.PageQuote0bbc1af48c91494eAb711248b9ad9d8e {
  display: flex;
  flex-wrap: wrap;
}
.ShortHeader7bb2acae24044f768a5a57e571b8807b {
  background-color: white;
  color: #030C22;
  position: relative;
}

.ShortHeader7bb2acae24044f768a5a57e571b8807b .background-image {
  background-image: url(https://mp1md-pub.s3.amazonaws.com/orgs/sample/city-background.jpg);
  background-size: cover;
  background-position: top;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.3;
}

.ShortHeader7bb2acae24044f768a5a57e571b8807b .header-top {
  align-items: center;
  display: flex;
  height: 4rem;
  justify-content: space-between;
  padding: 0 1.5rem;
  position: relative;
}

.ShortHeader7bb2acae24044f768a5a57e571b8807b .header-top .linked-logo {
  display: flex;
  justify-content: center;
}

.ShortHeader7bb2acae24044f768a5a57e571b8807b .header-top .logo {
  width: 8rem;
}

.ShortHeader7bb2acae24044f768a5a57e571b8807b .header-top .navigation {
  margin: 1rem;
  margin-top: 2.25rem;
}

.ShortHeader7bb2acae24044f768a5a57e571b8807b .header-top .Navigation {
  display: none;
  flex-grow: 1;
  gap: 2rem;
  font-family: Montserrat;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  color: #500064;
}

.ShortHeader7bb2acae24044f768a5a57e571b8807b .header-top .NavigationMenuButton {
  color: black;
  opacity: 0.5;
  display: flex;
}

.ShortHeader7bb2acae24044f768a5a57e571b8807b .header-top .NavigationMenuButton svg {
  width: 1.5rem;
  height: 1.5rem;
}

.SideCar {
  background-color: #ccc;
  padding: 2rem;
}

.SideCar .Navigation {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #500064;
  font-family: Montserrat;
  text-transform: uppercase;
}

.SideCar .Navigation .Link {
  display: block;
}
.SideCar .Navigation .Link:hover, .SideCar .Navigation .Link:active {
  filter: brightness(150%);
}

/* Larger screens */

@media (min-width: 900px) {
  .ShortHeader7bb2acae24044f768a5a57e571b8807b .header-top .linked-logo {
    flex-grow: 1;
  }
  .ShortHeader7bb2acae24044f768a5a57e571b8807b .header-top .logo {
    width: 9rem;
  }

  .ShortHeader7bb2acae24044f768a5a57e571b8807b .header-top .navigation {
    flex-grow: 4;
  }

  .ShortHeader7bb2acae24044f768a5a57e571b8807b .header-top .Navigation {
    display: flex;
  }

  .ShortHeader7bb2acae24044f768a5a57e571b8807b .header-top .NavigationMenuButton {
    display: none;
  }
}

@media (min-width: 1200px) {
  .ShortHeader7bb2acae24044f768a5a57e571b8807b .header-top {
    padding: 0 1.75rem;
    height: 5rem;
  }

  .ShortHeader7bb2acae24044f768a5a57e571b8807b .header-top .logo {
    width: 10rem;
  }
}
.DefaultHeaderTop691ad00b75d740caBca510e67bfb3efd {
  display: flex;
  align-items: stretch;
  position: relative;
  justify-content: space-between;
  height: 7rem;
  padding: 1rem;
  background-color: #5e67f2;
  color: white;
}

.DefaultHeaderTop691ad00b75d740caBca510e67bfb3efd .linked-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.DefaultHeaderTop691ad00b75d740caBca510e67bfb3efd .logo {
  color: white;
  font-size: 2rem;
  max-height: 4rem;
  width: 10rem;
}

.DefaultHeaderTop691ad00b75d740caBca510e67bfb3efd .navigation {
  display: flex;
  align-items: center;
  padding-bottom: 0.5rem;
  min-width: 2.5rem;
}

.DefaultHeaderTop691ad00b75d740caBca510e67bfb3efd .Navigation {
  display: none;
  flex-grow: 1;
  gap: 2rem;
  font-family: "Playfair Display";
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.04rem;
  justify-content: center;
  align-items: center;
}

.DefaultHeaderTop691ad00b75d740caBca510e67bfb3efd .Navigation .Link {
  color: white;
}

.DefaultHeaderTop691ad00b75d740caBca510e67bfb3efd .Navigation .Link:hover,
.DefaultHeaderTop691ad00b75d740caBca510e67bfb3efd .Navigation .Link:active {
  color: #ffec6e;
}

.SideCar {
  background-color: #ccc;
  padding: 2rem;
}

.SideCar .Navigation {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #500064;
  font-family: Playfair Display;
  text-transform: uppercase;
}

.SideCar .Navigation .Link {
  display: block;
}
.SideCar .Navigation .Link:hover, .SideCar .Navigation .Link:active {
  filter: brightness(150%);
}

.DefaultHeaderTop691ad00b75d740caBca510e67bfb3efd .NavigationMenuButton {
  opacity: 0.5;
  display: flex;
}

.DefaultHeaderTop691ad00b75d740caBca510e67bfb3efd .NavigationMenuButton:hover {
  opacity: 0.9;
}

.DefaultHeaderTop691ad00b75d740caBca510e67bfb3efd .NavigationMenuButton svg {
  width: 1.5rem;
  height: 1.5rem;
}

@media (min-width: 900px) {
  .DefaultHeaderTop691ad00b75d740caBca510e67bfb3efd {
    height: 9rem;
  }

  .DefaultHeaderTop691ad00b75d740caBca510e67bfb3efd .linked-logo {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .DefaultHeaderTop691ad00b75d740caBca510e67bfb3efd .logo {
    max-height: 5rem;
  }

  .DefaultHeaderTop691ad00b75d740caBca510e67bfb3efd .navigation {
    flex-grow: 4;
    align-items: end;
  }

  .DefaultHeaderTop691ad00b75d740caBca510e67bfb3efd .Navigation {
    display: flex;
  }

  .DefaultHeaderTop691ad00b75d740caBca510e67bfb3efd .NavigationMenuButton {
    display: none;
  }
}

@media (min-width: 1200px) {
  .DefaultHeaderTop691ad00b75d740caBca510e67bfb3efd .Navigation {
    font-size: 120%;
  }
  .DefaultHeaderTop691ad00b75d740caBca510e67bfb3efd .logo {
    width: 15.25rem;
  }
}
.SecondaryMarketingHeader0b7fa8f0A91e41628430A8c3f65cfad6 {
  height: 18rem;
  position: relative;
  color: #030C22;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.SecondaryMarketingHeader0b7fa8f0A91e41628430A8c3f65cfad6 .background-image {
  background-image: url(https://mp1md-pub.s3.amazonaws.com/spp/images/sky-background.jpg);
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -10;
}

.SecondaryMarketingHeader0b7fa8f0A91e41628430A8c3f65cfad6 .header-top-wrapper {
  position: relative;
}

.SecondaryMarketingHeader0b7fa8f0A91e41628430A8c3f65cfad6 .header-top-opacity {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: white;
  z-index: 0;
}

.SecondaryMarketingHeader0b7fa8f0A91e41628430A8c3f65cfad6 .header-top {
  z-index: 1;
  position: relative;
}
.AboutSusanMain02331a21860442819c99F7638b869459 {
  position: relative;
  flex: 1;
  background-color: #040404;
}

.AboutSusanMain02331a21860442819c99F7638b869459 .Button {
  padding: 0.75rem 3rem;
  border-width: 1px;
  border-style: solid;
}

.AboutSusanMain02331a21860442819c99F7638b869459 .button-container {
  margin: 3rem 0;
}

.AboutSusanMain02331a21860442819c99F7638b869459 .color-scheme-1 {
  background-color: #7e82bf;
  color: white;
}

.AboutSusanMain02331a21860442819c99F7638b869459 .color-scheme-1 .Button {
  border-color: white;
  color: white;
}

.AboutSusanMain02331a21860442819c99F7638b869459 .color-scheme-1 .Button:hover {
  background-color: #5e67f2;
}


.AboutSusanMain02331a21860442819c99F7638b869459 .color-scheme-2 {
  background-color: #c2c2c2;
  color: black;
}

.AboutSusanMain02331a21860442819c99F7638b869459 .color-scheme-2 .Button {
  border-color: black;
  color: black;
}

.AboutSusanMain02331a21860442819c99F7638b869459 .color-scheme-2 .Button:hover {
  background-color: #7e82bf;
  border-color: white;
  color: white;
}

.AboutSusanMain02331a21860442819c99F7638b869459 .centered-page-content {
  max-width: 1200px;
  margin: 0 auto;
}


.AboutSusanMain02331a21860442819c99F7638b869459 .background-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  inset: 0;
  background-repeat: no-repeat;
  background-position: center -14rem;
  background-size: 80rem;
  background-image: url(https://mp1md-pub.s3.amazonaws.com/orgs/tastesoftantra/images/susan-main.jpeg);
}

@media (min-width: 640px) {
  .AboutSusanMain02331a21860442819c99F7638b869459 .background-image {
      background-size: 93rem;
  }
}

.AboutSusanMain02331a21860442819c99F7638b869459 .text-block {
  position: relative;
  padding: 2rem;
  font-family: Playfair Display;
}

.AboutSusanMain02331a21860442819c99F7638b869459 .text-block.first {
  margin-top: 36rem;
}

.AboutSusanMain02331a21860442819c99F7638b869459 .container.color1 {
  background-color: #7e82bf;
  color: white;
}

.AboutSusanMain02331a21860442819c99F7638b869459 .text-block .opacity {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.5;
  background-color: white;
}

.AboutSusanMain02331a21860442819c99F7638b869459 .text-block .text {
  position: relative;
  font-size: 1.5rem;
  text-align: center;
  max-width: 640px;
  margin: 0 auto;
}

.AboutSusanMain02331a21860442819c99F7638b869459 .text-block .heading {
  font-family: Great Vibes;
  font-size: 3.5rem;
  line-height: 5rem;
}

.AboutSusanMain02331a21860442819c99F7638b869459 .text-block p {
  line-height: 210%;
  margin-bottom: 1.5rem;
}

@media (min-width: 640px) {
  .AboutSusanMain02331a21860442819c99F7638b869459 .text-block .heading {
    font-family: Great Vibes;
    font-size: 4rem;
    line-height: 6rem;
  }

  .AboutSusanMain02331a21860442819c99F7638b869459 .text-block p {
    line-height: 250%;
  }
}

.AboutSusanMain02331a21860442819c99F7638b869459 .image-block {
  position: relative;
  inset: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  /* background-image: url(https://mp1md-pub.s3.amazonaws.com/orgs/tastesoftantra/images/susan-bed.webp); */
  /* background-image: url(https://mp1md-pub.s3.amazonaws.com/orgs/tastesoftantra/images/susan-photo.jpeg); */
  background-image: url(https://mp1md-pub.s3.amazonaws.com/orgs/tastesoftantra/images/susan-between-offerings-and-testimonials.jpeg);

  height: 32rem;
}

.AboutSusanMain02331a21860442819c99F7638b869459 .testimonials {
  display: relative;
  display: flex;
  gap: 2rem;
  flex-direction: column;
  align-items: center;
}

.AboutSusanMain02331a21860442819c99F7638b869459 .testimonials > * {
  max-width: 37rem;
}

.AboutSusanMain02331a21860442819c99F7638b869459 .testimonials .testimonial {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.AboutSusanMain02331a21860442819c99F7638b869459 .testimonials p {
  margin-bottom: 0;
}

.AboutSusanMain02331a21860442819c99F7638b869459 .testimonials .bottom {
  text-align: center;
  margin-bottom: 1rem;
}

.AboutSusanMain02331a21860442819c99F7638b869459 .testimonials .bottom:after {
  content: '𐫱';
}

.AboutSusanMain02331a21860442819c99F7638b869459 .testimonials .image-container {
  display: flex;
  justify-content: center;
}

.AboutSusanMain02331a21860442819c99F7638b869459 .testimonials .image-container img {
  width: 160px;
  height: 160px;
  object-fit: cover;
  border-radius: 9999px;
}

.AboutSusanMain02331a21860442819c99F7638b869459 .contact-small {
  background-image: url(https://mp1md-pub.s3.amazonaws.com/orgs/tastesoftantra/images/susan-recline-landscape.webp);
  background-size: cover;
  background-position: center;
  height: 24rem;
  position: relative;
}

.AboutSusanMain02331a21860442819c99F7638b869459 .contact-container {
  position: relative;
}

@media(min-width: 768px) {
  .AboutSusanMain02331a21860442819c99F7638b869459 .contact-small {
    display: none;
  }
  .AboutSusanMain02331a21860442819c99F7638b869459 .contact-container {
    display: grid;
    grid-template-columns: repeat(2,minmax(0,1fr));
    height: 46rem;
  }
}

.AboutSusanMain02331a21860442819c99F7638b869459 .contact-container .image {
  background-image: url(https://mp1md-pub.s3.amazonaws.com/orgs/tastesoftantra/images/susan-recline.jpeg);
  background-size: 18rem;
  background-position: center;
  background-repeat: no-repeat; 
}

.AboutSusanMain02331a21860442819c99F7638b869459 .contact-form {
  position: relative;
  font-family: Playfair Display;
  padding: 3rem 2rem;
}

.AboutSusanMain02331a21860442819c99F7638b869459 .contact-form .heading {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.AboutSusanMain02331a21860442819c99F7638b869459 .contact-form p {
  margin-bottom: 0.5rem;
}

.AboutSusanMain02331a21860442819c99F7638b869459 .contact-form .button-container {
  margin-top: 2rem;
}

.AboutSusanMain02331a21860442819c99F7638b869459 .contact-form .Button {
  margin: 0 auto;
  display: block;
  width: max-content;
}